import * as types from "../constants/actionTypes";
import * as accountApi from "../../api/accountApi";

export function loadAccountSuccess(account) {
  return { type: types.LOAD_ACCOUNT_SUCCESS, account };
}

export function registerAccount(hash_id) {
  return function (dispatch) {
    return accountApi
      .getAccount(hash_id)
      .then((account) => {
        dispatch(loadAccountSuccess(account));
      })
      .catch((error) => {
        throw error;
      });
  };
}
