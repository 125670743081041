import * as types from "../constants/actionTypes";
import { initialState } from "./initialState";

export default function chatReducer(state = initialState.messages, action) {
  switch (action.type) {
    case types.ADD_MESSAGE:
      return [...state, { ...action.message }];
    case types.UPDATE_MESSAGE_STATUS:
      const internal_message_id = action.action.internal_message_id;

      return state.map((message) => {
        if (
          message.internal_message_id !== undefined &&
          message.internal_message_id === internal_message_id
        ) {
          return { ...message, status: action.action.status };
        } else {
          return message;
        }
      });
    case types.LOAD_MESSAGES_SUCCESS:
      return action.messages;
    case types.REMOVE_PREVIOUS_OPTIONS:
      return state.map((message) => {
        const { node_options, ...withoutOptions } = message;
        return withoutOptions;
      });
    case types.LOAD_MESSAGE_SUCCESS:
      return [...state, { ...action.message }];
    case types.CREATE_OPTION_SUCCESS:
      return [...state, { ...action.message }];
    case types.ERROR_API_CALL:
      return [...state, { ...action.message }];
    default:
      return state;
  }
}
