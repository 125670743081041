import * as types from "../constants/actionTypes";
import { initialState } from "./initialState";

export default function apiCallStatusReducer(
  state = initialState.isFetching,
  action
) {
  switch (action.type) {
    case types.WAITING_FOR_MESSAGE:
      return action.isFetching;
    default:
      return state;
  }
}
