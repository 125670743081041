import { handleResponse, handleError } from "./apiUtils";
//const baseUrl = "https://api.claims.staging.mitigateway.com/api/v1/claims/register_chat/";
const baseUrl =
  "https://api.claims.mitigateway.com/api/v1/claims/register_chat/";
//const baseUrl = "http://localhost:3000/api/v1/claims/register_chat/";

export function getAccount(hash_id) {
  return fetch(baseUrl + hash_id)
    .then(handleResponse)
    .catch(handleError);
}
