import React from "react";
import { connect } from "react-redux";
import "./Compose.css";
import * as chatActions from "../../redux/actions/chatActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Compressor from "compressorjs";
import _uniqueId from "lodash/uniqueId";

class Compose extends React.Component {
  constructor(props) {
    super(props);
    this.state = { input: "", image: "", cameraimage: "", galleryimage: "" };
  }
  handleImageChange = (event) => {
    this.setState({
      image: URL.createObjectURL(event.target.files[0]),
      image_file: event.target.files[0],
      input: "",
    });
  };

  handleInput = (event) => {
    event.preventDefault();
    if (this.state.input) {
      this.props.actions.saveChatOption(
        {
          payload: this.state.input,
          payload_type: "text",
        },
        null,
        this.props.account.jwt_token,
        {
          author: "user",
          status: "loading",
          internal_message_id: _uniqueId("user-"),
          node_messages: [
            {
              message_type: "text",
              message: this.state.input,
              created_at: new Date().getTime(),
            },
          ],
        }
      );
      this.setState({
        input: "",
      });
    } else if (this.state.image) {
      let self = this;
      self.setState({
        image: "",
        cameraimage: "",
        galleryimage: "",
      });
      new Compressor(this.state.image_file, {
        quality: 0.6,
        success(result) {
          let formData = new FormData();
          formData.append("payload", "image");
          formData.append("payload_type", "image");
          formData.append("payload_data", result, result.name);

          self.props.actions.saveChatOption(
            formData,
            "image",
            self.props.account.jwt_token,
            {
              author: "user",
              status: "loading",
              internal_message_id: _uniqueId("user-"),
              node_messages: [
                {
                  message_type: "image",
                  message: URL.createObjectURL(result),
                  created_at: new Date().getTime(),
                  formData,
                },
              ],
            }
          );
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  handleChange = (e) => {
    this.setState({ input: e.target.value });
  };

  removeImage = (event) => {
    event.preventDefault();
    this.setState({
      image: "",
    });
  };

  render() {
    return (
      <div className="compose">
        {this.state.image === "" && (
          <input
            type="text"
            className="compose-input"
            name="compose-input"
            id="compose-input"
            placeholder="írjon be egy üzenetet"
            value={this.state.input}
            onChange={this.handleChange}
          />
        )}
        {this.state.image !== "" && (
          <div className="image-input">
            <div className="image-container">
              <img
                className="user-camera-image"
                src={this.state.image}
                alt="Use your camera"
              />
              <span
                href="#"
                className="image-remove"
                onClick={this.removeImage}
                alt="Remove"
              >
                <i className="toolbar-button ion-ios-trash"></i>
              </span>
            </div>
          </div>
        )}
        <button
          className="button-send"
          aria-label="Send..."
          disabled={!this.state.input && !this.state.image}
        >
          <i
            className="toolbar-button ion-ios-send"
            onClick={this.handleInput}
          ></i>
        </button>

        <label>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            capture="environment"
            value={this.state.cameraimage}
            onChange={this.handleImageChange}
            id="camera-image"
          />
          <i className="toolbar-button ion-ios-camera" id="camera-icon"></i>
        </label>

        {/* <label>
                    <input
                        style={{ display: 'none' }}
                        type='file'
                        accept="image/*"
                        value={this.state.galleryimage}
                        onChange={this.handleImageChange}
                    />
                    <i className="toolbar-button ion-ios-image"></i>
                </label> */}

        {this.props.rightItems}
      </div>
    );
  }
}

Compose.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    messages: state.messages,
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(chatActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Compose);
