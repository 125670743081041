import React from 'react';
import './Toolbar.css';

export default function Toolbar(props) {
    const { title, rightItems } = props;
    return (
        <div className="toolbar">
            <div className="left-items">{title}</div>
            {/* <h1 className="toolbar-title"></h1> */}
            <div className="right-items">
                {rightItems}
                {/* <button className="btn-header">Restart Claim</button>
                <button className="btn-header">Undo</button> */}
            </div>
        </div>
    );
}