export const ADD_MESSAGE = "ADD_MESSAGE";
export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const LOAD_MESSAGE_SUCCESS = "LOAD_MESSAGE_SUCCESS";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const ERROR_API_CALL = "ERROR_API_CALL";
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const CREATE_OPTION_SUCCESS = "CREATE_OPTION_SUCCESS";
export const REMOVE_PREVIOUS_OPTIONS = "REMOVE_PREVIOUS_OPTIONS";
export const WAITING_FOR_MESSAGE = "WAITING_FOR_MESSAGE";
