import React from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as chatActions from "../../redux/actions/chatActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _uniqueId from "lodash/uniqueId";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./Message.css";
import { disableElement, disableInputs } from "../../utils";
class Message extends React.Component {
  constructor(props) {
    super(props);

    if (
      this.props.data !== undefined &&
      this.props.data.timestamp !== undefined
    ) {
      this.friendlyTimestamp = moment(this.props.data.timestamp).format("LLLL");
    }
  }

  renderTextMessageComponent = () => {
    return (
      <div
        className="bubble"
        title={this.friendlyTimestamp}
        dangerouslySetInnerHTML={{ __html: this.props.data.message }}
      ></div>
    );
  };

  renderImageMessageComponent = () => {
    return (
      <div className="message-image">
        <Loader
          className="loader"
          type="ThreeDots"
          color="#00BFFF"
          visible={this.props.status === "loading"}
        />
        <img src={this.props.data.message} alt="User Graphic" />
      </div>
    );
  };

  renderMessage = (data) => {
    if (this.props.complete) {
      disableInputs();

      document.getElementsByName("compose-input")[0].placeholder =
        "Köszönjük, végeztünk és kiléphet.";

      disableElement("camera-icon");

      return <div className="complete">Köszönjük, végeztünk és kiléphet. </div>;
    }
    if (data.message_type === "image") {
      return this.renderImageMessageComponent();
    } else {
      return this.renderTextMessageComponent();
    }
  };

  clickButton = (e) => {
    this.props.actions.saveChatOption(
      {
        payload: e.target.value,
        payload_type: "button",
      },
      null,
      this.props.account.jwt_token,
      {
        author: "user",
        status: "loading",
        internal_message_id: _uniqueId("user-"),
        node_messages: [
          {
            message_type: "text",
            message: e.target.value,
            created_at: new Date().getTime(),
          },
        ],
      }
    );
  };

  retrySend = (e) => {
    e.preventDefault();
    if (this.props.internalMessageId !== undefined) {
      // Find the message in the redux store

      let messageToRetry = this.props.messages.filter((message) => {
        if (
          message.internal_message_id !== undefined &&
          message.internal_message_id === this.props.internalMessageId
        ) {
          return message;
        }
        return false;
      });

      if (messageToRetry[0].node_messages[0].message_type === "image") {
        this.props.actions.retryChatOption(
          messageToRetry[0].node_messages[0].formData,
          "image",
          this.props.account.jwt_token,
          messageToRetry[0]
        );
      } else {
        this.props.actions.retryChatOption(
          {
            payload: messageToRetry[0].node_messages[0].message,
            payload_type: "text",
          },
          "text",
          this.props.account.jwt_token,
          messageToRetry[0]
        );
      }
    }
  };

  renderOptions = function (data) {
    if (data.options !== undefined && data.options.length > 0) {
      return (
        <div className="option-list">
          {Object.keys(this.props.data.options).map((keyOuter) => {
            return Object.keys(this.props.data.options[keyOuter]).map(
              (keyInner) => {
                return (
                  <button
                    key={`${keyOuter}`}
                    value={this.props.data.options[keyOuter][keyInner]}
                    onClick={(e) => this.clickButton(e)}
                  >
                    {keyInner}
                  </button>
                );
              }
            );
          })}
        </div>
      );
    }
  };

  render() {
    let retryBox = "";
    if (this.props.status === "failed" || this.props.status === "retrying") {
      retryBox = (
        <div className="bubble-container">
          <div className="error-retry message">
            Hiba történt az üzenet küldésekor. Kérjük, ellenőrizze internet
            kapcsolatát.
            {this.props.status === "failed" && (
              <button className="text-link" onClick={(e) => this.retrySend(e)}>
                Próbálja újra?
              </button>
            )}
            {this.props.status === "retrying" && <span>Retrying....</span>}
          </div>
        </div>
      );
    }

    return (
      <div
        className={[
          "message",
          `${this.props.isMine ? "mine" : ""}`,
          `${this.props.status === "failed" ? "error" : ""}`,
          `${this.props.status === "loading" ? "loading" : ""}`,
        ].join(" ")}
      >
        {this.props.showTimestamp && (
          <div className="timestamp">{this.friendlyTimestamp}</div>
        )}

        {this.props.data.options === undefined && (
          <div className="bubble-container">
            <div className="avatar-box">
              {this.props.author === "bot" && this.props.endsSequence && (
                <div className="image-avatar">
                  <img
                    src="autotal-logo.png"
                    alt="Autotal"
                    className="profile-pic"
                  />
                </div>
              )}
            </div>

            {this.renderMessage(this.props.data)}
          </div>
        )}

        <div className="bubble-container">
          <div className="avatar-box"></div>
          {this.renderOptions(this.props.data)}
        </div>

        {retryBox}
      </div>
    );
  }
}

Message.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    messages: state.messages,
    account: state.account,
    isFetching: state.apiCallsInProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(chatActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);
