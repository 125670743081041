import React from "react";

import "./TypingIndicator.css";

export const TypingIndicator = ({ showIndicator }) => {
  return (
    <div
      className="typing-indicator"
      style={{ display: showIndicator ? "block" : "none" }}
    >
      <div className="message">
        <div className="bubble-container">
          <div className="avatar-box">
            <div className="image-avatar">
              <img
                src="autotal-logo.png"
                alt="Autotal"
                className="profile-pic"
              />
            </div>
          </div>
          <div className="typingIndicatorContainer">
            <div className="typingIndicatorBubble">
              <div className="typingIndicatorBubbleDot"></div>
              <div className="typingIndicatorBubbleDot"></div>
              <div className="typingIndicatorBubbleDot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
