import { handleResponse, handleError } from "./apiUtils";
//const baseUrl = "https://api.claims.staging.mitigateway.com/api/v1/claims/chat";
const baseUrl = "https://api.claims.mitigateway.com/api/v1/claims/chat";
// const baseUrl = "http://localhost:3000/api/v1/claims/chat";

export function getCurrentMessage(jwt_token = null) {
  return fetch(baseUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${jwt_token}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMessages(jwt_token = null) {
  return fetch(baseUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${jwt_token}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

// TODO: build this
export function saveChatOption(option, type = null, jwt_token = null) {
  let token = jwt_token;

  let headers = {};
  let body = "";
  if (type === "image") {
    headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    body = option;
  } else {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    body = JSON.stringify(option);
  }
  return fetch(baseUrl, {
    method: "POST",
    body: body,
    headers: headers,
  })
    .then(handleResponse)
    .catch(handleError);
}
