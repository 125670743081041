import React from "react";
import Compose from "../Compose/Compose";
import Toolbar from "../Toolbar/Toolbar";
import Message from "../Message/Message";
import moment from "moment";
import * as chatActions from "../../redux/actions/chatActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { handleError } from "../../api/apiUtils";
import _uniqueId from "lodash/uniqueId";

import "./MessageList.css";
import { TypingIndicator } from "../common/TypingIndicator/TypingIndicator";

const MY_USER_ID = "user";

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: undefined,
      complete: false,
      firstMessageLoaded: false,
    };
  }

  componentDidUpdate() {
    if (
      this.props.account !== null &&
      this.state.firstMessageLoaded === false
    ) {
      this.props.actions
        .loadMessage(this.props.account.jwt_token)
        .catch((error) => {
          handleError(error);
        });
      this.setState({ firstMessageLoaded: true });
    }

    if (this.props.messages.length > 0) {
      this.scrollToBottom();
    }
  }

  iOSversion = () => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
  };

  scrollToBottom = () => {
    let ver = this.iOSversion();

    if (ver !== undefined && ver[0] <= 10) {
      //var objDiv = document.getElementById("message-list");
      document.body.scrollTop = 9999999999;
    } else {
      setTimeout(() => {
        scroll.scrollToBottom({
          containerId: "message-list",
          duration: 500,
        });
      }, 100);
    }
  };

  renderMessages = () => {
    let currentMessageIndex = 0;
    let messageCollection = [];

    if (this.props.messages) {
      const messageCount = this.props.messages.length;

      while (currentMessageIndex < messageCount) {
        let i = 0;
        let messageOutputs = 0;
        let messageStatus = "complete";
        let messageInternalId = "";

        if (this.props.messages[currentMessageIndex].status !== undefined) {
          messageStatus = this.props.messages[currentMessageIndex].status;
        }

        if (
          this.props.messages[currentMessageIndex].internal_message_id !==
          undefined
        ) {
          messageInternalId = this.props.messages[currentMessageIndex]
            .internal_message_id;
        }

        if (
          this.props.messages[currentMessageIndex].node_messages !== undefined
        ) {
          messageOutputs = this.props.messages[currentMessageIndex]
            .node_messages.length;
        }

        while (i < messageOutputs) {
          let previous = this.props.messages[currentMessageIndex].node_messages[
            i - 1
          ];
          let current = this.props.messages[currentMessageIndex].node_messages[
            i
          ];
          let author = this.props.messages[currentMessageIndex].author;
          if (!author) {
            author = "bot";
          }
          let next = this.props.messages[currentMessageIndex].node_messages[
            i + 1
          ];
          let isMine = author === MY_USER_ID;
          let currentMoment = moment(current.created_at);
          let prevBySameAuthor = false;
          let nextBySameAuthor = false;
          let startsSequence = true;
          let endsSequence = true;
          let showTimestamp = currentMessageIndex === 0;

          if (previous) {
            let previousMoment = moment(previous.created_at);
            let previousDuration = moment.duration(
              currentMoment.diff(previousMoment)
            );
            prevBySameAuthor = previous.author === author;

            if (prevBySameAuthor && previousDuration.as("hours") < 1) {
              startsSequence = false;
            }

            if (previousDuration.as("hours") < 1) {
              showTimestamp = false;
            }
          }

          if (next) {
            let nextMoment = moment(next.created_at);
            let nextDuration = moment.duration(nextMoment.diff(currentMoment));
            nextBySameAuthor = next.author === author;

            if (nextBySameAuthor && nextDuration.as("hours") < 1) {
              endsSequence = false;
            }
          }

          let messageKey = _uniqueId("system-");
          if (
            this.props.messages[currentMessageIndex].node_messages[i]
              .internal_message_id !== undefined
          ) {
            messageKey = this.props.messages[currentMessageIndex].node_messages[
              i
            ].internal_message_id;
          }
          messageCollection.push(
            <Message
              key={messageKey}
              id={messageKey}
              isMine={isMine}
              author={author}
              status={messageStatus}
              internalMessageId={messageInternalId}
              startsSequence={startsSequence}
              endsSequence={endsSequence}
              showTimestamp={showTimestamp}
              data={current}
            />
          );

          // Proceed to the next message.
          i += 1;
        }

        if (this.props.messages[currentMessageIndex].is_exit_node) {
          messageCollection.push(
            <Message
              key={_uniqueId("system-") + "-end"}
              complete={true}
              status={messageStatus}
              internalMessageId={messageInternalId}
              data="finished"
            />
          );
        }

        if (
          this.props.messages[currentMessageIndex].node_options !== undefined
        ) {
          let messageOptions = this.props.messages[currentMessageIndex]
            .node_options.length;
          let y = 0;
          let options = [];
          while (y < messageOptions) {
            if (
              this.props.messages[currentMessageIndex].node_options[y]
                .option_type !== "image"
            ) {
              let option_text = this.props.messages[currentMessageIndex]
                .node_options[y].option_text;
              let option_payload = this.props.messages[currentMessageIndex]
                .node_options[y].payload;
              let option_object = {};
              option_object[option_text] = option_payload;
              options.push(option_object);
            }
            y++;
          }

          // API returns image options that don't need to be presented to the user, dont create components for these
          if (options.length > 0) {
            let options_data = {
              author: "bot",
              type: "",
              options: options,
            };
            messageCollection.push(
              <Message
                key={_uniqueId("system-") + "-options"}
                isMine={false}
                status={messageStatus}
                internalMessageId={messageInternalId}
                data={options_data}
              />
            );
          }
        }
        currentMessageIndex++;
      }
    }

    return messageCollection;
  };

  render = () => {
    return (
      <div className="message-list">
        <Toolbar title="Autotál Kft." />

        <div className="message-list-container">
          {/* {this.props.loading ? <Spinner /> : (
                        this.renderMessage())} */}
          {this.renderMessages()}
        </div>

        <TypingIndicator showIndicator={this.props.isFetching} />
        <Compose />
        <div>
          <div
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    messages: state.messages,
    account: state.account,
    isFetching: state.apiCallsInProgress,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(chatActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
