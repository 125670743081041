export function addClass(classname, element) {
  var cn = element.className;
  //test for existance
  if (cn.indexOf(classname) !== -1) {
    return;
  }
  //add a space if the element already has class
  if (cn !== "") {
    classname = " " + classname;
  }
  element.className = cn + classname;
}

export function disableElement(element_id) {
  let el = document.getElementById(element_id);
  addClass("disabled", el);
}

export function disableInputs() {
  var inputs = document.getElementsByTagName("INPUT");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].disabled = true;
  }
}
