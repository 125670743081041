import { combineReducers } from 'redux';
import messages from './chatReducer';
import apiCallsInProgress from './apiStatusReducer';
import account from './accountReducer';

const rootReducer = combineReducers({
    messages,
    apiCallsInProgress,
    account
});

export default rootReducer;